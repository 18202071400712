import React from "react";

const Landing = () => {
  return (
    <>
      <div
        id="landing"
        className="w-full h-screen flex justify-center items-center bg-stone-600"
      >
        <h1 className="text-red-600 font-black text-3xl text-center">
          IMPORTANT. WEBSITE IS STILL UNDER DEVELOPMENT. <br />
          DESIGN IS <u>NOT FINAL.</u>
          <br /> MLH BANNER IS ONLY A PLACEHOLDER.
        </h1>
      </div>
    </>
  );
};

export default Landing;
