import React from "react";

const AvatarCard = ({ img, name }) => {
  return (
    <>
      <div className="card__animation flex flex-col bg-slate-100 rounded-lg px-6 pt-8">
        <img
          className="rounded-lg w-24 sm:w-32 shadow-xl border-solid border-2 border-black"
          src={img}
          alt="rafa!!!"
        />
        <p className="text-center py-4 font-bold">{name}</p>
      </div>
    </>
  );
};

export default AvatarCard;
