import React from "react";

const AvatarCircle = ({ img, name }) => {
  return (
    <>
      <div className="flex flex-col">
        <img className="rounded-full w-24 sm:w-32" src={img} alt="rafa!!!" />
        <p className="text-center mt-2 font-bold">{name}</p>
      </div>
    </>
  );
};

export default AvatarCircle;
