import React, { useState } from "react";
import ScheduleItem from "./ScheduleItem";
import ScheduleDay from "./ScheduleDay";

const Schedule = () => {
  const [activeDay, setActiveDay] = useState("Friday");

  const eventsData = {
    Friday: [
      { time: "09:00 AM", event: "Check-In" },
      { time: "11:00 AM", event: "Opening Ceremony" },
      { time: "12:00 PM", event: "Lunch" },
      { time: "02:00 PM", event: "Workshop #1" },
    ],
    Saturday: [
      { time: "08:00 AM", event: "Doors Open" },
      { time: "10:00 AM", event: "Breakfast" },
      { time: "11:00 AM", event: "Continue Coding!!!" },
      { time: "12:00 PM", event: "Pizza Lunch" },
      { time: "02:00 PM", event: "Workshop #1" },
    ],
    Sunday: [
      { time: "08:30 AM", event: "Doors Open" },
      { time: "10:00 AM", event: "Presentations" },
      { time: "12:00 PM", event: "Lunch" },
      { time: "01:00 PM", event: "Judging and Prizes" },
    ],
  };

  return (
    <>
      <div
        id="schedule"
        className="w-full h-screen flex flex-col justify-center items-center bg-lime-500"
      >
        <div className="schedule__title mb-12">
          <h1 className="text-center font-bold">Schedule</h1>
          <p>(subject to change)</p>
        </div>
        <div className="flex justify-between mb-8 w-2/5 bg-slate-200 rounded-full py-4 px-10">
          {["Friday", "Saturday", "Sunday"].map((day) => (
            <ScheduleDay
              key={day}
              day={day}
              isActive={activeDay === day}
              handleDayClick={() => setActiveDay(day)}
            />
          ))}
        </div>
        {activeDay && (
          <ul className="schedule__items w-full flex flex-col justify-center items-center">
            {eventsData[activeDay].map((item, index) => (
              <ScheduleItem key={index} time={item.time} event={item.event} />
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Schedule;
