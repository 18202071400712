import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";

const FaqQuestion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        onClick={() => toggleOpen()}
        className="question__wrapper flex flex-col bg-slate-100
      rounded-lg py-2 px-3 cursor-pointer"
      >
        <div className="question__title--wrapper flex justify-between items-center">
          <h1 className="question__title text-xl">{question}</h1>
          <FontAwesomeIcon
            className={`arrow__icon ${isOpen ? "open" : ""} text-xl px-1`}
            icon={faCaretUp}
          />
        </div>
        <p className={`question__para text-md ${isOpen ? "open" : ""}`}>
          {answer}
        </p>
      </div>
    </>
  );
};

export default FaqQuestion;
