import React from "react";

const ScheduleItem = ({ time, event }) => {
  return (
    <>
      <li className="schedule__item w-1/2 flex mb-4">
        <h1 className="bg-white font-semibold rounded-sm w-24 text-center p-2 shadow-md">{time}</h1>
        <div className="schedule__item--right w-full flex justify-center items-center">
          <h1 className="w-full h-[30px] bg-blue-500 text-white font-semibold tracking-wide text-center p-1 rounded-sm">
            {event}
          </h1>
        </div>
      </li>
    </>
  );
};

export default ScheduleItem;
