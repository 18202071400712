import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dataSponsors } from "../data/sponsors";

const Sponsors = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        id="sponsors"
        className="w-full h-full flex flex-col justify-center items-center bg-rose-500 py-10"
      >
        <h1 className="mb-6 text-xl font-semibold">Sponsors/Partners</h1>
        <div className="slider__container w-full max-w-2xl m-auto mb-4">
          <Slider {...settings}>
              {dataSponsors.map((item) => {
                return (
                  <div
                  key={item.id}
                  className="!flex !justify-center"
                  >
                    <img
                      src={item.img}
                      alt={item.title}
                      className="h-16 w-16 object-contain"
                      />
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Sponsors;
