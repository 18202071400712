export const dataSponsors = [
  {
    id: 1,
    title: "Facebook",
    img: "https://1000logos.net/wp-content/uploads/2021/10/logo-Meta.png",
  },
  {
    id: 2,
    title: "Amazon",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/2560px-Amazon_logo.svg.png",
  },
  {
    id: 3,
    title: "Apple",
    img: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg",
  },
  {
    id: 4,
    title: "Google",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1200px-Google_2015_logo.svg.png",
  },
  {
    id: 5,
    title: "Microsoft",
    img: "https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg",
  },
  {
    id: 6,
    title: "X",
    img: "https://upload.wikimedia.org/wikipedia/commons/5/57/X_logo_2023_%28white%29.png",
  },
  {
    id: 7,
    title: "Netflix",
    img: "https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg",
  },
  {
    id: 8,
    title: "Spotify",
    img: "https://upload.wikimedia.org/wikipedia/commons/1/19/Spotify_logo_without_text.svg",
  },
  {
    id: 9,
    title: "TikTok",
    img: "https://upload.wikimedia.org/wikipedia/en/thumb/a/a9/TikTok_logo.svg/2560px-TikTok_logo.svg.png",
  },
  {
    id: 10,
    title: "Snapchat",
    img: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Snapcode_barcode.svg",
  },
  {
    id: 11,
    title: "LinkedIn",
    img: "https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png",
  },
  {
    id: 12,
    title: "Slack",
    img: "https://upload.wikimedia.org/wikipedia/commons/7/76/Slack_Icon.png",
  },
  {
    id: 13,
    title: "Shopify",
    img: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Shopify_logo_2018.svg",
  },
];
