import React from "react";
import FaqQuestion from "./FaqQuestion";

const Faq = () => {
  return (
    <>
      <div
        id="faq"
        className="flex flex-col justify-center items-center bg-violet-500 py-8" // pt-8 pb-44
      >
        <h1 className="faq__header font-semibold text-3xl mb-8">
          Frequently Asked Questions
        </h1>
        <div className="faq__container max-w-xs flex flex-col gap-8">
          <FaqQuestion
            question="What is Viking Hacks 2024?"
            answer="Viking Hacks 2024 is a Diablo Valley College student-run hackathon that 
          will be held on April 20th, 2024. It is a 24-hour event where students can collaborate 
          on projects, attend workshops, and network with industry professionals."
          />
          <FaqQuestion
            question="What is a Hackathon?"
            answer="A hackathon is a design sprint-like event in which computer programmers
          and others involved in software development, including graphic
          designers, interface designers, project managers, and others, often
          including domain experts, collaborate intensively on software projects."
          />
          <FaqQuestion
            question="What if I have no experience or ideas?"
            answer="No problem! Viking Hacks is open to all skill levels and we will have
            workshops and mentors to help you out. We will also have a team
            formation session where you can find a team to work with."
          />
          <FaqQuestion
            question="What if I don't have a team?"
            answer="We will have a team formation session where you can find a team to work
            with. You can also choose to work alone if you prefer."
          />
          <FaqQuestion
            question="What should I bring?"
            answer="Bring your laptop, charger, and any other hardware you might need. We
            also recommend bringing a change of clothes, toiletries, and a
            sleeping bag if you plan on staying overnight."
          />
          <FaqQuestion
            question="What if I have dietary restrictions?"
            answer="We will have a variety of food options available, including vegetarian
            and vegan options. If you have any specific dietary restrictions,
            please let us know and we will do our best to accommodate you."
          />
          <FaqQuestion
            question="How many people can be on a team?"
            answer="You can have up to 4 people on a team. You can also choose to work alone
            if you prefer."
          />
        </div>
      </div>
    </>
  );
};

export default Faq;
