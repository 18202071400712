import React from 'react'
import AvatarCircle from './AvatarCircle'
import Default from '../assets/Default_pfp.svg.png'

const Organizers = () => {
  return (
    <>
    <div
        id="organizers"
        className="w-full h-full flex flex-col justify-center items-center bg-teal-500 pb-24"
      >
        <h1 className="my-8 text-3xl font-semibold">Viking Hacks Organizers</h1>
        <div className="judge__wrapper flex flex-col items-center px-4 w-full lg:w-1/2">
          <div className="judges flex flex-wrap justify-center gap-x-8 gap-y-8">
            <AvatarCircle img={Default} name="TBA" />
            <AvatarCircle img={Default} name="TBA" />
            <AvatarCircle img={Default} name="TBA" />
            <AvatarCircle img={Default} name="TBA" />
            <AvatarCircle img={Default} name="TBA" />
            <AvatarCircle img={Default} name="TBA" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Organizers