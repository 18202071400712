import React from "react";
import AvatarCard from "./AvatarCard";
import Default from "../assets/Default_pfp.svg.png";

const Judges = () => {
  return (
    <>
      <div
        id="judges"
        className="w-full h-full pb-8 flex flex-col justify-center items-center bg-teal-500"
      >
        <h1 className="my-8 text-3xl font-semibold">Meet Your Judges!</h1>
        <div className="judge__wrapper flex flex-col items-center px-4 w-full lg:w-1/2">
          <div className="judges flex flex-wrap justify-center gap-x-8 gap-y-8">
            <AvatarCard img={Default} name="TBA" />
            <AvatarCard img={Default} name="TBA" />
            <AvatarCard img={Default} name="TBA" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Judges;
