import React from "react";

const About = () => {
  return (
    <>
      <div
        id="about"
        className="w-full h-screen flex justify-center items-center bg-zinc-500"
      >
        <div className="about__left w-1/3 flex flex-col mr-64 gap-4">
          <h1 className="font-semibold">Theme: Education</h1>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Voluptatibus voluptatem soluta nostrum quos reprehenderit ipsam
            numquam laboriosam mollitia laborum. Corporis incidunt, vitae at
            ipsam obcaecati ad maiores magni laboriosam tempore quisquam ipsa,
            consequatur exercitationem, optio est officiis temporibus aspernatur
            tempora.
          </p>
        </div>
        <div className="about__right">
          <h1 className="font-bold">INSERT VIDEO HERE</h1>
        </div>
      </div>
    </>
  );
};

export default About;
