import React from "react";
import NavbarItem from "./NavbarItem";
import logo from "../assets/dsc_icon-01.svg";

const Navbar = () => {
  return (
    <>
      <div className="navbar__container flex justify-center">
        <nav
          className="navbar__wrapper flex justify-between items-center z-10
        mt-2 mb-2
        h-16 bg-white fixed w-[98%] rounded-xl"
        >
          <div className="navbar__logo ml-16">
            <img className="w-16 min-w-16" src={logo} alt="GDSC LOGO" />
          </div>
          <ul className="navbar__items flex mr-40">
            <NavbarItem href="#landing" title="Home" />
            <NavbarItem href="#about" title="About" />
            <NavbarItem href="#schedule" title="Schedule" />
            <NavbarItem href="#judges" title="Judges" />
            <NavbarItem href="#sponsors" title="Sponsors" />
            <NavbarItem href="#faq" title="FAQ" />
            <NavbarItem href="#register" title="Register" />
          </ul>
          <img
            className="max-w-24 min-w-16 absolute right-16 top-0 w-[10%]"
            src="https://s3.amazonaws.com/logged-assets/trust-badge/2024/mlh-trust-badge-2024-white.svg"
            alt="Major League Hacking 2024 Hackathon Season"
          />
        </nav>
      </div>
    </>
  );
};

export default Navbar;
