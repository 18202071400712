import React from "react";

const NavbarItem = ({ title, href }) => {
  return (
    <>
      <li
        className="navbar__item ml-4 mr-4 relative hover:cursor-pointer font-bold text-md 
      hover:text-blue-500 
      after:content-[''] after:bg-red-500 after:h-[3px] after:w-0
      after:left-0 after:-bottom-0 after:rounded-xl after:absolute after:duration-200
      hover:after:w-full"
      >
        <a href={href}>{title}</a>
      </li>
    </>
  );
};

export default NavbarItem;
