import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import VikingHacks2023 from '../assets/Viking_Hacks_Logo_4.png'

const Footer = () => {
  return (
    <>
      <div className="footer__wrapper relative flex justify-center bg-[#4285F4]">
        <div className="background absolute bottom-[53px] w-full overflow-hidden z-0 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#4285F4"
              fill-opacity="1"
              d="M0,192L34.3,192C68.6,192,137,192,206,202.7C274.3,213,343,235,411,245.3C480,256,549,256,617,261.3C685.7,267,754,277,823,245.3C891.4,213,960,139,1029,133.3C1097.1,128,1166,192,1234,213.3C1302.9,235,1371,213,1406,202.7L1440,192L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
            ></path>
          </svg>
        </div>
        <footer className="flex justify-between items-center bg-[#4285F4] z-10 text-[#4285F4] p-4 w-64">
          <a className="flex bg-white p-2 rounded-full hover:opacity-80" href="https://github.com/rafmojica/viking-hacks-2024">
            <FontAwesomeIcon className="size-8" icon={faGithub} />
          </a>
          <a className="flex bg-white p-2 rounded-full hover:opacity-80" href="#">
            <FontAwesomeIcon className="size-8" icon={faEnvelope} />
          </a>
          <a className="flex bg-white p-2 rounded-full hover:opacity-80" href="#">
            <FontAwesomeIcon className="size-8" icon={faInstagram} />
          </a>
          <a className="flex bg-white p-2 rounded-full hover:opacity-80" target="_blank" href="https://dvcdevelopers.com/">
            <img className="size-8" src={VikingHacks2023} alt="Viking Hacks 2023" />
          </a>
        </footer>
      </div>
    </>
  );
};

export default Footer;
