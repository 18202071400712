import Navbar from "./components/Navbar";
import About from "./components/About";
import Schedule from "./components/Schedule";
import Judges from "./components/Judges";
import Sponsors from "./components/Sponsors";
import Faq from "./components/Faq";
import "./index.css";
import Footer from "./components/Footer";
import Landing from "./components/Landing";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Organizers from "./components/Organizers";

function App() {
  return (
    <>
      <Navbar />

      {/* <Parallax pages={4}>
        <ParallaxLayer offset={0} style={{backgroundColor: 'blue'}}>
          <h1>Viking Hacks 2024</h1>
        </ParallaxLayer>
        <ParallaxLayer offset={1}>
          <h1>Register Today!</h1>
        </ParallaxLayer>
      </Parallax> */}

      <Landing />
      <About />
      <Schedule />
      <Judges />
      <Sponsors />
      <Faq />
      <Organizers />
      <Footer />
    </>
  );
}

export default App;
