import React from "react";

const ScheduleDay = ({ day, isActive, handleDayClick }) => {
  return (
    <>
      <button
        onClick={handleDayClick}
        className={`ScheduleDay__item py-2 px-4 hover:bg-blue-500 font-semibold hover:text-white hover:rounded-lg ${
          isActive ? "bg-blue-500 text-white font-semibold rounded-lg" : ""
        }`}
      >
        {day}
      </button>
    </>
  );
};

export default ScheduleDay;
